/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import radio from '@/components/common/rxRadio';
import checkbox from '@/components/common/rxCheckbox';
import listItem from '@/components/common/ListItem';
Vue.component(listItem.name, listItem);
Vue.component(radio.name, radio);
Vue.component(checkbox.name, checkbox);
export default {
  name: "rx-userselect",
  data() {
    return {
      list: [],
      page: 0,
      total: 0,
      searchName: "",
      merage: true,
      historyParams: [],
      single: true,
      selectUser: "",
      selectUserId: ""
    };
  },
  props: {
    param: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick(item) {
      this.selectUser = item;
    },
    searchByName() {
      console.info('searchByName');
    },
    getInstUser() {
      console.info('getInstUser');
      var url = _baseUrl + "/bpm/core/bpmMessageBoard/getInstUser.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          procInstId: this.param
        }
      }).then(response => {
        console.info(response.data.data);
        this.list = response.data.data;
      }).catch(error => {
        console.log(error);
      });
    },
    getFilePath(fileId) {
      return this.getRealUrl() + "/sys/core/file/previewFile.do?fileId=" + fileId;
    },
    ok() {
      this.$emit('ok', this.selectUser);
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  created() {
    this.getInstUser();
  }
};