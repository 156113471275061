/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
import { loadRefreshList } from '@/assets/app.js';
import userselect from '@/components/common/UserSelect';
Vue.component(userselect.name, userselect);
export default {
  data() {
    return {
      list: [],
      targetUser: {},
      targetUserList: [],
      messageList: [],
      newMessageContent: "",
      procInstId: "",
      loginUser: {},
      total: 0,
      page: 0,
      direction: true,
      msgBtnType: "disabled",
      showUserDialog: false,
      fromAt: false,
      messageObject: {},
      earlyMsgTime: "",
      bgColor: false,
      fromPath: ""
    };
  },
  computed: {
    reverseList: function () {
      return this.list.sort(asc);
    }
  },
  methods: {
    //留言
    leaveMessage() {
      if (this.newMessageContent.length == 0) {
        this.toast(null, "请输入内容");
        return;
      }
      var url = _baseUrl + '/mobile/bpm/leaveMessage.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          procInstId: this.procInstId,
          messageContent: this.newMessageContent,
          atIds: this.targetUserList.join(",")
        }
      }).then(response => {
        //消息实体
        this.messageObject = response.data.data;
        //清空留言内容、候选人列表
        this.newMessageContent = '';
        this.loadList();
      }).catch(error => {
        console.log(error);
      });
    },
    getMessageBoard(procInstId) {
      var url = _baseUrl + '/mobile/bpm/getMessageBoard.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          procInstId: procInstId
        }
      }).then(response => {
        if (response.data.success) {
          this.messageList = response.data.data;
        } else {
          alert(response.data.message);
        }
      }).catch(error => {
        console.log(error);
      });
    },
    loadList() {
      this.direction = true;
      var url = _baseUrl + "/mobile/bpm/getMessageBoard.do";
      var lastObj = {};
      var params = "pageIndex=0&pageSize=10&procInstId=" + this.procInstId;
      loadRefreshList(url, params, this, data => {
        console.info("data:" + data);
      });
    },
    upSwiperLoad() {
      this.direction = false;
      if (this.fromAt) {
        this.page = 0;
      }
      var url = _baseUrl + "/mobile/bpm/getMessageBoard.do";
      var params = "pageIndex=" + this.page + "&pageSize=10&procInstId=" + this.procInstId + "&earlyMsgTime=" + this.earlyMsgTime;
      loadRefreshList(url, params, this, data => {
        this.total = data.total;
        this.fromAt = false;
      }, true);
    },
    selectUser(selectUser) {
      this.targetUser = selectUser;
      this.targetUserList.push(this.targetUser.userId);
      this.newMessageContent = this.newMessageContent + this.targetUser.fullname + " ";
      console.info("所选@人列表：" + this.targetUserList);
      this.showUserDialog = false;
    },
    //给候选ID推送
    pushToTarget(messageId) {
      //targetUserList\title\text\body
      var url = _baseUrl + '/mobile/bpm/pushMsgToUser.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          targetUserList: this.targetUserList.join(","),
          title: "留言板有人@你了",
          text: this.newMessageContent,
          body: '{"fromAt":true,"action":"bpmMessageBoard","procInstId":' + this.procInstId + ',"messageId":' + messageId + '}'
        }
      }).then(response => {
        if (!response.data.success) return;
      }).catch(error => {
        console.log(error);
      });
    },
    pushSelf() {
      console.info('pushSelf');
      //this.fromAt = true;

      router.push({
        name: 'bpmMessageBoard',
        query: {
          'messageId': '2400000007711720'
        }
      });
    },
    changeReadStatus() {
      var url = _baseUrl + '/bpm/core/bpmMessageBoard/updateWeedSelfId.do';
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          procInstId: this.procInstId
        }
      }).then(response => {
        if (!response.data.success) return;
      }).catch(error => {
        console.log(error);
      });
    },
    goBackToTarget() {
      alert("fromPage:" + this.fromPath);
    }
  },
  created() {
    this.loginUser = RxUtil.getSessionJSON("loginUser");
    this.procInstId = this.$route.params.procInstId;
    this.loadList();
  },
  updated() {
    setTimeout(() => {
      this.bgColor = true;
    }, 1000);

    //滚动屏高度
    let viewHeight;
    if (document.getElementsByClassName('chat-display')[0]) {
      viewHeight = document.getElementsByClassName('chat-display')[0].scrollHeight;
    } else {
      return;
    }
    if (!this.fromAt) {
      //将滑动条滚动到最底
      if (document.getElementsByClassName('chat-display').length == 0) {
        return;
      }
      if (this.direction) {
        document.getElementsByClassName('chat-display')[0].scrollTop = viewHeight;
      } else {
        document.getElementsByClassName('chat-display')[0].scrollTop = 0;
      }
    } else {
      //锚点相对父级对象高度
      let anchorHeight = document.getElementById('anchor-' + this.messageId).offsetTop;
      //偏移调整
      document.getElementsByClassName('chat-display')[0].scrollTop = anchorHeight - 60;
    }
  },
  watch: {
    '$route': 'loadList'
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromPath = from.path;
    });
  },
  beforeRouteLeave(to, from, next) {
    next();
  }
};
var sortKey = 'createTime';
var asc = function (x, y) {
  return x[sortKey] > y[sortKey] ? 1 : -1;
};